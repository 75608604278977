<template>
    <div class="apply-founders">
        <div class="container">
            <div class="row no-gutters">
                <div class="col-12 col-xl d-flex flex-column justify-content-center align-items-center  align-items-xl-start">
                    <h1>Want to turn your knowledge<br>revenue?</h1>
                    <p>Apply to join our Founding Creators Program!</p>
                    <button class="btn" type="button" @click="applyNow">
                        Apply now
                    </button>
                </div>
                <div class="col-12 col-xl-auto d-flex justify-content-center">
                    <figure class="smartphone-illustration">
                        <img src="@assets/img/founders-landing/smartphone-illustration.svg" alt="Smartphone Illustration">
                    </figure>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ApplyFounders",
    props: {
        isValidInvitation: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        applyNow() {
            if (this.$route.name == "founders-landing") {
                this.$modal.show(this.isValidInvitation ? "get-started-invited" : "get-started-no-invited");
            } else {
                this.$router.push({ name: "founders-landing" });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.apply-founders {
    background: linear-gradient(90deg, #02AAB0 0%, #00CDAC 100%);
    z-index: 1;
    position: relative;

    @media(max-width: $xl) {
        padding-top: 50px;
    }

    .container {
        position: relative;

        h1 {
            font-size: 36px;
            line-height: 44px;
            font-weight: 600;
            color: black;
            margin-bottom: 15px;

            @media(max-width: $xl) {
                text-align: center;
            }
        }

        p {
            font-size: 24px;
            line-height: 36px;
            font-weight: 400;
            margin-bottom: 20px;

            @media(max-width: $xl) {
                text-align: center;
            }
        }

        button.btn {
            background-color: white;
            font-weight: 600;
            font-size: 16px;
        }

        .smartphone-illustration {
            margin-bottom: 0;
            transform: scale(1.2);
            transform-origin: bottom;
            margin-bottom: 0;

            @media(max-width: $xl) {
                transform: scale(0.9);
            }
        }
    }
}
</style>
